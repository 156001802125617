<template>
  <b-row>
    <demand-filters :demand-type="demandType" @set-demand-type="setDemandType" />
    <b-col :md="6">
      <chartjs-container :title="departmentChartTitle" :extended-view-button="departmentChartHasMeaningfulData"
                         :extended-state="extendedDepartmentView" @set-extended-view="setDepartmentExtendedView">
        <!-- vue-ChartJS does not update properly with the height change. We do render conditionally with a transition state to force update between two charts-->
        <chartjs-component-horizontal-bar-chart v-if="printPartialView && departmentChartHasMeaningfulData"
                                                :height="departmentCharHeight" :chart-data="chartData" />
        <chartjs-component-horizontal-bar-chart v-else-if="printExtendedView && departmentChartHasMeaningfulData"
                                                :height="extendedDepartmentChartHeight" :chart-data="chartData" />
        <p v-else>
          Aucune information disponible
        </p>
      </chartjs-container>
    </b-col>
    <b-col :md="6">
      <chartjs-container :title="regionChartTitle">
        <chartjs-component-horizontal-bar-chart v-if="regionsChartHasMeaningfulData" :height="regionChartHeight"
                                                :chart-data="regionData" />
        <p v-else>
          Aucune information disponible
        </p>
      </chartjs-container>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
} from 'bootstrap-vue'
import departments from '@/assets/departments.json'
import DemandFilters from '../DemandFilters.vue'
import ChartjsContainer from '../ChartjsContainer.vue'
import ChartjsComponentHorizontalBarChart from '../charts-components/ChartjsComponentHorizontalBarChart.vue'
import useStatisticsRouteFetch from '../useStatisticsRouteFetch'
import {
  computedConfiguration, watchConfigurationWithDemandType, BASE_CHART_HEIGHT, AUGMENTED_CHART_HEIGHT, chartHasMeaningfulData, TABS,
} from './baseConfiguration'

export default {
  components: {
    BCol,
    BRow,
    ChartjsContainer,
    ChartjsComponentHorizontalBarChart,
    DemandFilters,
  },
  data() {
    return {
      tabname: TABS.LOCATION,
      departmentChartTitle: 'Départements',
      regionChartTitle: 'Régions',
      extendedDepartmentView: false,
      maxDepartmentDisplay: 15,
      chartData: {},
      departmentRawResponse: [],
      departmentCharHeight: BASE_CHART_HEIGHT,
      extendedDepartmentChartHeight: AUGMENTED_CHART_HEIGHT,
      regionChartHeight: BASE_CHART_HEIGHT,
      regionData: {},
      departmentTransition: false,
      demandType: 0,
      chartLabel: 'Nombre d\'appels',
    }
  },
  computed: {
    ...computedConfiguration,
    console() {
      return console
    },
    departmentChartHasMeaningfulData() {
      return chartHasMeaningfulData(this.chartData)
    },
    regionsChartHasMeaningfulData() {
      return chartHasMeaningfulData(this.regionData)
    },
    printExtendedView() {
      return this.extendedDepartmentView && !this.departmentTransition
    },
    printPartialView() {
      return !this.extendedDepartmentView && !this.departmentTransition
    },
  },
  watch: {
    ...watchConfigurationWithDemandType,
  },
  methods: {
    setDemandType(value) {
      this.demandType = value
    },
    setDepartmentExtendedView() {
      this.extendedDepartmentView = !this.extendedDepartmentView
      this.departmentTransition = true
      this.chartData = this.buildDepartmentChart()
      /*
       This is a stoopid hack to force the chart to completely rerender. The chartjs component does not update properly with the height change
       Using a direct instruction out of a setTimeout will not work, the async instruction is needed
      */
      setTimeout(() => {
        this.departmentTransition = false
      }, 1)
    },
    fetchRouteOnActive() {
      if (this.active) {
        this.fetchRoute()
      }
    },
    async fetchRoute() {
        const response = await useStatisticsRouteFetch(this.demandType)
        response.sort((a, b) => b.value - a.value)
        this.departmentRawResponse = response
        this.chartData = this.buildDepartmentChart()
        this.regionData = this.buildRegionChart()
    },
    buildDepartmentChart() {
      if (this.extendedDepartmentView) {
        return this.formatExtendedDepartmentView()
      }
      return this.formatStandardDepartmentView()
    },
    formatExtendedDepartmentView() {
      const extendedData = this.formatExtendedData()
      const labels = extendedData.map(entry => entry[0])
      const values = extendedData.map(entry => entry[1])

      return {
        labels,
        datasets: [
          {
            label: this.chartLabel,
            data: values,
            backgroundColor: '#FF6384',
          },
        ],
      }
    },
    formatExtendedData() {
      const correspondance = this.addDepartmentsSentByResponse(this.departmentRawResponse)
      const withMissingDepartments = this.addMissingDepartments(correspondance)
      return this.sortByNameAndValue(withMissingDepartments)
    },
    addDepartmentsSentByResponse(data) {
      const correspondance = {}
      data.forEach(entry => {
        const department = departments.find(dpt => String(dpt.num_dep) === String(entry.label))?.dep_name || 'Non renseigné'
        correspondance[department] = entry.value
      })
      return correspondance
    },
    addMissingDepartments(correspondance) {
      const correspondanceCopy = { ...correspondance }
      const labels = this.getAllDepartmentsLabels()
      labels.forEach(label => {
        correspondanceCopy[label] ??= 0
      })
      return correspondanceCopy
    },
    formatStandardDepartmentView() {
      const formattedLabels = this.formatDepartmentsLabels(this.departmentRawResponse)
      return {
        labels: formattedLabels,
        datasets: [
          {
            label: this.chartLabel,
            data: this.departmentRawResponse.map(entry => entry.value).slice(0, this.maxDepartmentDisplay),
            backgroundColor: '#FF6384',
          },
        ],
      }
    },
    formatDepartmentsLabels(data) {
      return data
        .map(entry => (entry.label ? this.formatDepartmentLabel(entry.label) : 'Département non renseigné'))
        .slice(0, this.maxDepartmentDisplay)
    },
    formatDepartmentLabel(label) {
      return departments.find(dpt => String(dpt.num_dep) === String(label))?.dep_name
    },
    getAllDepartmentsLabels() {
      return departments.map(dpt => dpt.dep_name).sort()
    },
    buildRegionChart() {
      const regionsWithValues = this.formatRegionValues(this.departmentRawResponse)
      const sortedRegions = this.sortByNameAndValue(regionsWithValues)
      const formattedRegionLabels = sortedRegions.map(entry => entry[0])
      const formatedRegionValues = sortedRegions.map(entry => entry[1])

      return {
        labels: formattedRegionLabels,
        datasets: [
          {
            label: this.chartLabel,
            data: formatedRegionValues,
            backgroundColor: '#16B5EA',
          },
        ],
      }
    },
    getAllRegionLabels() {
      return [...new Set(departments.map(dpt => dpt.region_name))]
    },
    formatRegionValues(data) {
      const labels = this.getAllRegionLabels()
      const correspondance = this.addRegionsSentByResponse(data)
      return this.addMissingRegionLabels(labels, correspondance)
    },
    addRegionsSentByResponse(data) {
      const correspondance = {}
      data.forEach(entry => {
        const region = departments.find(dpt => String(dpt.num_dep) === String(entry.label))?.region_name || 'Non renseignée'
        correspondance[region] = correspondance[region] + entry.value || entry.value
      })
      return correspondance
    },
    addMissingRegionLabels(labels, correspondance) {
      const correspondanceCopy = { ...correspondance }
      labels.forEach(label => {
        if (!correspondance[label]) {
          correspondanceCopy[label] = 0
        }
      })

      return correspondanceCopy
    },
    // Allows for alphabetical order when values are equal for two entries
    sortByNameAndValue(regions) {
      return Object.entries(regions)
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort by name
        .sort((a, b) => b[1] - a[1]) // Sort by value
    },
  },
}

</script>
